<script>
export default {
  data: () => ({
    isMarked: false
  }),
  props: {
    report: {
      required: true,
      type: Object
    }
  }
}
</script>
<template lang="pug">
  .row.ed-grid.m-grid-3
    .m-cols-1
      .cell {{ report.fecha }}
    .m-cols-1
      .cell {{ report.name }}
    .m-cols-1
      .cell
        //- input.checkbox(
        //-   type="checkbox"
        //-   style="width:20px;height:20px"
        //-   v-model="isMarked"
        //- )
        input.checkbox(
          type="checkbox"
          id="marked"
          v-model="isMarked"
          :checked="isMarked"
        )
</template>
<style lang="scss" scoped>
  .checkbox {
    width: 18px;
    height: 18px;
    border: 1px solid;
    display: inline-block;
  }
  .checkbox:checked{
    color: black;
    background: #006AB6;
    border-color: #006AB6;
      &::after{
        content: "✔";
        position: relative;
        bottom: 1px;
        left: 2px;
        color: white;
      }
  }

</style>
