<script>
import ReportTableRow from '@/components/02-molecule/reportTable/default.vue'
export default {
  components: { ReportTableRow },
  props: {
    reports: {
      required: true,
      type: Array
    }
  }
}
</script>
<template lang="pug">
  .c-table.c-session-table
    .c-table__head.ed-grid.m-grid-3
      .m-cols-1 Fecha de reg.
      .m-cols-1 Nombre del informe
      .m-cols-1
    .c-table__body
      ReportTableRow(
        v-for="(report, key) in reports"
        :key="key"
        :report="report"
      )
</template>
<style lang="sass" scoped>
</style>
