<script>
import ReportTable from '@/components/03-organism/reportTable/default.vue'
export default {
  components: { ReportTable },
  data: () => ({
    reports: [
      {
        fecha: '01-10-2005',
        name: 'reporte'
      },
      {
        fecha: '01-10-2005',
        name: 'Reporte'
      },
      {
        fecha: '01-10-2005',
        name: 'Reporte'
      }
    ]
  })
}
</script>
<template lang="pug">
  .container
    .header
      //- .container_icon
      //-   i.icon-arrow_delante_ico.icon_return
      .title Informe
    .options
      .btn
        img(src="@/assets/images/printing_machine.png")
        p Imprimir
      .btn
        img(src="@/assets/images/download.png")
        p Descargar
    ReportTable(
      :reports="reports"
    )
</template>
<style lang="scss" scoped>
  $color: #236192;
  $secondary-color: #E0E0E0;
  $color3: #3284ef;
  .container {
    display:flex;
    width: 100%;
    padding: 20px;
    flex-flow: column nowrap;
    .header {
      display: flex;
      flex-flow: row nowrap;
      margin: 0 0 20px 0;
      align-items: center;
    }
    .title {
      font-weight: 500;
      color: $color;
      font-size: 25px;
      align-self: center;
      margin: 0 10px 0 0 !important;
    }
  }
  .container_icon {
    padding: 10px;
    border-radius: 50%;
    background: $color;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 10px 0 0;
    .icon_return {
      transform: rotate(180deg);
      color:white;
    }
  }
  .options {
    width: 100%;
    display:flex;
    padding: 10px;
    justify-content: flex-end;
    * {
      margin: 0 5px;
    }
    .btn {
      display:flex;
      flex-flow: row nowrap;
      padding: 10px;
      box-shadow: 2px 2px 15px 2px rgba(30, 6, 75, 0.15);
      border-radius: 50px;
      background: #FFFFFF;
      outline: none;
      border: none;
      text-decoration: none;
      cursor: pointer;
      p {
        color: blue;
      }
      img {
        height: 100%;
        width: auto;
      }
    }

  }
  table {
    thead {
      .row-header {
        background: #EFEFEF;
        border-radius: 16px 16px 0px 0px;
        padding: 10px;
        color: $color
      }
    }
  }
  .table {
    width: 100;
    display: flex;
    flex-flow: column nowrap;
    .row {
      display: flex;
      flex-flow: row nowrap;
      color: $color;
      &--header {
        border-radius: 16px 16px 0px 0px;
        background: #EFEFEF;
      }
    }
    .celd {
      padding: 10px 15px;
    }
  }
</style>
